import React, { useEffect, useState } from 'react';

const Loading = ({isLoading, imgs, onPreloadImg, isLoadingInstagram, isPreloadingImages, isLoadingGallery}) => {
    const [loadingText, setLoadingText] = useState('');

    useEffect(() => {
        let timer = null;
        let dots = '';
        if (isLoading || isPreloadingImages || isLoadingInstagram || isLoadingGallery) {
            timer = setInterval(() => {
                dots = dots + '.';
                if (dots.length > 3) {
                    dots = '';
                }
                setLoadingText(loadingText + dots);
            }, 600);
        }
        return () => {
            if (timer) {
                return clearInterval(timer);
            }
            return timer;
        };
    // eslint-disable-next-line
    }, [isLoading]);

    const handleImageLoaded = (e) => {
        setTimeout(function(){
            onPreloadImg(false);
        }, 2000);
    };

    const handleImageErrored = (e) => {
        onPreloadImg(false);
    };

    let allImgs = [];
    if (imgs?.length) {
        allImgs = imgs.map((img, index) => {
            return (
                <img
                    key={index}
                    className="hidden-image"
                    src={img}
                    onLoad={handleImageLoaded}
                    onError={handleImageErrored}
                    alt="Louis Cato"
                />
            );
        });
    }

    return (
        <>
            <div className="loading-screen bg-pan-left">
                <p className="tracking-in-expand">One moment<span className="loading-dots">{loadingText}</span></p>
            </div>
            {allImgs}
        </>
    );
}

export default Loading;
