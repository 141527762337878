import React from 'react';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="site-footer">
      <p className={`p-4`}>&copy; {currentYear} Louis Cato</p>
    </div>
  );
}

export default Footer;
